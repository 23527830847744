/** @format */

import { Route, Routes } from 'react-router-dom';
import Previewwrapper from '../pages/Components/previewwrapper/previewwrapper.component';

import FilterContextProvider from "../context/filter.context";
import { useMessageContext } from "../context/Message.context";
import ErrorBox from "../pages/Components/Errorbox/Errorbox.component";
import PageNotFound from "../pages/Components/pageNotFound/pageNotFound.component";
import Successbox from "../pages/Components/Successbox/Successbox.component";
import AboutUST3 from "../pages/Template3/about_us/about_us.t3";
import BlogsT3 from "../pages/Template3/BlogList/Blogs.t3";
import ContactUs from "../pages/Template3/contact_us/contact_us.t3";
import Template3Home from "../pages/Template3/home/home.t3";
import IdxWrapperT3 from "../pages/Template3/IdxWrapper/IdxWrapper.t3";
import BlogView from "../pages/utilComponent/BlogView/BlogView";
import Builderdeals from "../pages/utilComponent/builderdeals/builderdeals.component";
import BuilderdealsPropertyDetails from "../pages/utilComponent/builderdealsPropertyDetails/builderdealsPropertyDetails.t1";
import IdxListing from "../pages/utilComponent/idxListing/IdxListing.utilComponent";
import PropertyView from "../pages/utilComponent/idxView/idxView.component";
import { PROPERTY_TYPE_CLASSES } from "../pages/utilComponent/PropertyCard/PropertyCard.utilComponent";
import SoldListing from "../pages/utilComponent/soldListing/soldListing.utilcomponent";
import ListingMap from "../pages/utilComponent/Maps/ListingMap.untilComponent";
import Sellerguide from "../pages/utilComponent/Sellerguide/Sellerguide";
import Buyerguide from "../pages/utilComponent/Buyerguide/Buyerguide";
import ExclusiveView from "../pages/utilComponent/ExclusiveListingSlider/ExclusiveView";
import ExclusiveListingCards from "../pages/Template1/ExclusiveListing/ExclusiveListing2.component";
import MortageCalculator from '../pages/utilComponent/MortageCalculator/MortageCalculator';
import BlogSlider from '../pages/Template3/Components/blog_slider/blog_slider.component';
import BlogListing from '../pages/utilComponent/bloglisting/BlogListing.utilcomponent';
import FeaturePropertyCard from '../pages/utilComponent/FeaturePropertySlider/FeaturedPropertyCard.component';
export default function Template3Route() {
  const { state } = useMessageContext();
  return (
    <>
      <Routes>
        <Route path=':preview?' element={<Previewwrapper />}>
          <Route
            path='home'
            element={<Template3Home {...{ templateData: null }} />}
          />

          <Route
            path='about_us'
            element={<AboutUST3 {...{ templateData: null }} />}
          />
          <Route
            path='contact_us'
            element={<ContactUs {...{ templateData: null }} templateId={2} />}
          />
          <Route path='blog_list' element={<IdxWrapperT3 />}>
            <Route index element={<BlogsT3 />} />
            <Route path='blogview' element={<BlogView />} />
          </Route>
          <Route
            path="featuredcards"
            element={<IdxWrapperT3 />}>
            <Route
              index
              element={<FeaturePropertyCard />}
            />
          </Route>
          <Route
            path='*'
            element={
              <FilterContextProvider>
                <IdxWrapperT3 />
              </FilterContextProvider>
            }
          >
            <Route path='idxlisting'>
              <Route
                index
                element={
                  <IdxListing
                    type='template3'
                    cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                  />
                }
              />
              <Route
                path=':mls?/idxView/:propertyId'
                element={
                  <PropertyView
                    cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                  />
                }
              />
            </Route>
            <Route path='map'>
              <Route
                index
                element={
                  <ListingMap
                    cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                  />
                }
              />

              <Route
                path='idxView/:propertyId'
                element={
                  <PropertyView
                    cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path='soldlisting'
            element={
              <FilterContextProvider>
                <IdxWrapperT3 />
              </FilterContextProvider>
            }
          >
            <Route
              index
              element={
                <SoldListing
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path=':type/soldView/:propertyId'
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path='builderdeals/'
            element={
              <FilterContextProvider>
                <IdxWrapperT3 />
              </FilterContextProvider>
            }
          >
            <Route
              index
              element={
                <Builderdeals
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path='builder_view/:propertyId'
              element={
                <BuilderdealsPropertyDetails
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path='soldlisting'
            element={
              <FilterContextProvider>
                <IdxWrapperT3 />
              </FilterContextProvider>
            }
          >
            <Route
              index
              element={
                <SoldListing
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path=':type/soldView/:propertyId'
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route path="buyerguide" element={<IdxWrapperT3 />}>
            <Route index element={<Buyerguide tempId={3} {...{ templateData: null }} />} />
          </Route>

          <Route path="sellerguide" element={<IdxWrapperT3 />}>
            <Route
              index
              element={<Sellerguide tempId={3} {...{ templateData: null }} />}
            />
          </Route>

          <Route path="mortagecalculator" element={<IdxWrapperT3 />}>
            <Route index element={<MortageCalculator />} />
          </Route>
          <Route
            path="exclusivecards"
            element={<IdxWrapperT3 />}>
            <Route
              index
              element={<ExclusiveListingCards />}
            />
          </Route>

          <Route
            path="blogs"
            element={<IdxWrapperT3 />}>
            <Route
              index
              element={<BlogListing />}
            />
            <Route path="blogview" element={<BlogView />} />
          </Route>

          <Route
            path='builderdeals/'
            element={
              <FilterContextProvider>
                <IdxWrapperT3 />
              </FilterContextProvider>
            }
          >
            <Route
              index
              element={
                <Builderdeals
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path='builder_view/:propertyId'
              element={
                <BuilderdealsPropertyDetails
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />

            <Route path="exclusivecards" element={<IdxWrapperT3 />}>
              <Route
                index
                element={<ExclusiveListingCards />}
              />
            </Route>
            <Route
              path="exclusiveview"
              element={<IdxWrapperT3 />}>
              <Route
                index
                element={<ExclusiveView />}
              />
            </Route>
          </Route>
          <Route path='*' element={<IdxWrapperT3 />}>
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
      {state.ErrorShow && <ErrorBox />}
      {state.SuccessShow && <Successbox />}
    </>
  );
}

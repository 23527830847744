import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { MdOutlineArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import '../../Template1/ExclusiveListing/ExclusiveListing2.css';
import '../../utilComponent/PropertyCard1/PropertyCard1.style.css'

export type mlsDataProps = {
  _id?: string;
  mlsNumber: string;
  address: string;
  price: number | string;
  beedroom: string | number;
  bathroom: string | number;
  garage: string | number;
  image: string;
};

export default function FeaturePropertyCard() {
  const [Property, setProperty] = useState<mlsDataProps[]>([]);
  const navigate = useNavigate();

  const Redirect = (mlsNumber: string) => {
    navigate(`../idxlisting/mls/idxView/${mlsNumber}`);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const GetMlsListing = async (): Promise<void> => {
      const token = Cookies.get('token');
      try {
        const GetData = await axios.get('/api/get-feature-properties', {
          cancelToken: source.token,
          headers: { 'x-access-token': token },
        });
        console.log("featured", GetData.data.data)
        setProperty(GetData.data.data);
      } catch (err) {
        throw new Error(err);
      }
    };
    GetMlsListing();
    return () => {
      source.cancel('Api call got canceled by cleanup.');
    };
  }, []);

  return (
    <>
      <div className="container-flex exclisveBanner">
        <div className="excluSection">
          <div className="container">
            <h1>Featured Listing</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      {Property && Property.map((data) => (
        <div className="exclusive-container" onClick={() => Redirect(data.mlsNumber)}>
          <div className="altman-properties-item">
            <div className="sub-main">
              <div className="altman-properties-item-inner-wrapper">
                <div className="altman-properties-item-photo">
                  <canvas width="529" height="460" className="lazy-load-canvas-element"
                    data-lazyload-src={`${data.image}`}
                    style={{ backgroundImage: `url(${data.image})` }}></canvas>
                </div>
                <div className="altman-properties-item-content-status-city ease">
                  <div>Featured Listing</div>
                  {/* <div>Los Angeles</div> */}
                </div>
                <div className="altman-properties-item-content ease">
                  <div className="altman-properties-item-content-address">
                    {data.address}
                    {/* <div>Los Angeles</div> */}
                  </div>
                  <ul className="altman-properties-item-content-features ease ps-0">
                    <li> {data.beedroom} BD</li>
                    <li> {data.bathroom} BA</li>
                    <li> {data.garage} GA</li>
                  </ul>
                  <div className="altman-properties-item-content-price ease">${data.price}</div>
                </div>
                <div className="altman-properties-item-link-label ease">View Details</div>
              </div>
            </div>
          </div>
        </div>

      ))
      }
    </>
  )
}

